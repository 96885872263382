import { cloneElement } from "react"
import Link from "./Link"

const GroupIcons = ({ icons, links, iconsProps }) => {
    return (
        <div className="flex flex-row flex-grow w-full justify-center items-center mt-4">
            {Object.entries(links).map(([key, value], index) => {
                if (icons[key])
                    return (
                        <Link link={value} key={index}>
                            {cloneElement(icons[key], iconsProps)}
                        </Link>
                    )
                return null
            })}
        </div>
    );
}

export default GroupIcons;