import ProfilePicture from "./component/ProfilePicture";
import Profile from './image/Profile.jpg'
import config from './config.json'
import SocialIcons from "./component/SocialIcons";
import LinkButton from "./component/LinkButton";
import CryptoIcons from "./component/CryptoIcons";

const App = () => {
  const {name, links, socials, crypto} = config

  
  return (
    <div className={`flex flex-col justify-center items-center pt-4`}>
      <ProfilePicture image={Profile}/>
      <p className="m-2">{name}</p>
      <div className="w-full md:w-2/3 lg:w-1/3 px-5 m-2">
        {links.map(({title, link}, index) => (
          <LinkButton title={title} link={link} key={index}/> 
        ))}
      </div>
      <SocialIcons socials={socials}/>
      <CryptoIcons crypto={crypto}/>
    </div>
  );
}

export default App