import { FaEthereum, FaBitcoin } from "react-icons/fa";
import GroupIcons from "./GroupIcons";

const cryptoIcons = {
    eth : <FaEthereum/>,
    btc : <FaBitcoin/>
}

const props = { className: 'm-2', size: 32 }

const CryptoIcons = ({ crypto }) => {
    return (
        <GroupIcons icons={cryptoIcons} links={crypto} iconsProps={props} />
    );
}

export default CryptoIcons;