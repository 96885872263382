import { BsTwitter, BsInstagram, BsDiscord, BsFacebook, BsTwitch } from "react-icons/bs"
import GroupIcons from "./GroupIcons";

const socialIcons = {
    twitter: <BsTwitter />,
    discord: <BsDiscord />,
    twitch: <BsTwitch />,
    facebook: <BsFacebook />,
    instagram: <BsInstagram />,
}

const props = { className: 'm-2', size: 32 }

const SocialIcons = ({ socials }) => {
    return (
        <GroupIcons icons={socialIcons} links={socials} iconsProps={props} />
    );
}

export default SocialIcons;