import Button from "./Button";
import Link from "./Link";

const LinkButton = ({ title, link }) => {
    return (
        <Link link={link}>
            <Button>{title}</Button>
        </Link>
    );
}

export default LinkButton;